import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { Term } from '../components/glossary/term';
import { RenderHead } from "../components/head";


//
// head

export const Head = (props) => {

    const { data } = props;  // props.pageContext is also `term`
    const { term } = data;
    const title = term.title;
    const description = term.body;
    const keywords = [term.title];

    return RenderHead({ title, description, keywords });
};



//
// component

const GlossaryTemplate = (props) => {

    const { location, data } = props;  // props.pageContext is also `term`
    const { term } = data;

    return (
        <Layout location={ location } >
            { Term( term ) }
        </Layout>
    );
};

export default GlossaryTemplate;


export const pageQuery = graphql`
query term ($title_unique: String!)
{
  term: internalGlossaryJson (title_unique: { eq: $title_unique }) 
  {    
    body
    created
    id
    question
    related { 
      created_epoch_ts
      headline
      headline_pre
      published_epoch_ts
      published_date_str
      slug_path
      tags { type value }
      type
    }
    title
    title_unique
  }
}`;
