import React from 'react';
import { Link } from 'gatsby';
import { colors } from '../colors';
import { headline } from '../brief/headline';

import * as styles from './term.module.css';


const BackLink = () => {
    return (
        <Link
          style={{
              fontFamily: `"Overpass", sans-serif`,
              fontSize: `14px`,
              fontWeight: `600`,
              color: colors.green,
              textDecoration: `none`,
              letterSpacing: `0.35px`
          }}
          to={ `/glossary`} >
          Back to Glossary
        </Link>
    );
};

const Title = ({ title }) => {
    return (
        <div
          style={{
              margin: `50px 0 0`,
              fontFamily: `"Overpass", sans-serif`,
              fontSize: `24px`,
              fontWeight: `bold`,
              color: colors.black
          }} >
          { title }
        </div>
    );
};

const Question = ({ question }) => {
    return (
        <div
          style={{
              margin: `30px 0 0`,
              fontFamily: `"Overpass", sans-serif`,
              fontSize: `22px`,
              color: colors.black
          }} >
          { `What ` + question + `?` }
        </div>
    );
};

const Body = ({ body }) => {
    return (
        <div
          style={{
              margin: `25px 0 0`,
              fontFamily: `"Overpass", sans-serif`,
              fontSize: `18px`,
              color: colors.black
          }} >
          { body }
        </div>
    );
};

const Headline = (brief) => {
    return (
        <div
          className={ styles.related }
          key={ Math.random() } >
          { headline(brief) }
        </div>
    );
};

const Related = ({ related }) => {

    const briefs = related.map((b) => {
        if ( b.tags !== undefined )
            b['tags'] = { visible: b.tags.map(({value}) => value) };
        return b;
    }, []);
    
    return (
        <div
          style={{
              margin: `150px 0 0`,
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `flex-start`,
              alignItems: `flex-start`
          }} >

          <div
            style={{
                fontFamily: `"Overpass", sans-serif`,
                fontSize: `16px`,
                letterSpacing: `0.16px`,
                color: colors.black
            }} >
            Related Briefs
          </div>
          
          <div
            className={ styles.relatedContainer } >
            { briefs.map( Headline ) }
          </div>
          
        </div>
    );
};

export const Term = (data) => {
    
    return (
        <div className={ styles.container } >

          <div
            className={ styles.innerContainer } >
            
            { BackLink() }
            { Title(data) }
            { Question(data) }
            { Body(data) }
            { Related(data) }

          </div>
          
        </div>
    );
};
